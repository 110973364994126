<template>
<div class="ContractApplication">
  合同申请
</div>
</template>

<script>
export default {
name: "ContractApplication"
}
</script>

<style scoped lang="scss">
.ContractApplication{
  border: 10px solid #edeff2;
}
</style>
